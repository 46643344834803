<template>
  <div>
    <CommonAttendanceUpdateTimeModal
      :attendance="attendance"
      :employee="employee"
      :hold="isBusy"
      @onSubmitClick="updateAttendance"
    ></CommonAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import CommonAttendanceUpdateTimeModal from "@/modules/core/components/attendance/CommonAttendanceUpdateTimeModal";
import { mapGetters } from "vuex";
export default {
  name: "StaffAttendanceUpdateTimeModal",
  components: { CommonAttendanceUpdateTimeModal },
  props: {
    employee: {
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateAttendance(form) {
      this.$store
        .dispatch("updateStaffAttendanceTime", {
          form: form,
        })
        .then(() => {
          this.$bvModal.msgBoxOk("Manual entry.", {
            centered: true,
            title: "Success",
          });
          this.$emit("formSubmitted", form);
        });
    },
  },
  computed: {
    ...mapGetters({
      isBusy: "getStaffAttendanceTimeBusy",
    }),
  },
};
</script>

<style scoped></style>
