<template>
  <div>
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @expandDetails="handleExpand"
      @showManageModal="showManageModal"
      @changePage="handlePageChange"
      @showUpdateModal="showUpdateModal"
    ></EmployeeDailyAttendanceTable>

    <ClockRecordModal :is-busy="false" :records="records"></ClockRecordModal>
    <StaffAttendanceManagementModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    ></StaffAttendanceManagementModal>

    <StaffAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    ></StaffAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import EmployeeDailyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeDailyAttendanceTable";
import { mapGetters } from "vuex";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import dayjs from "dayjs";
import StaffAttendanceManagementModal from "@/modules/school/components/management/attendance/staff/StaffAttendanceManagementModal";
import StaffAttendanceUpdateTimeModal from "@/modules/school/components/management/attendance/staff/StaffAttendanceUpdateTimeModal";

export default {
  name: "StaffDailyAttendance",
  components: {
    StaffAttendanceUpdateTimeModal,
    StaffAttendanceManagementModal,
    ClockRecordModal,
    EmployeeDailyAttendanceTable,
  },
  data() {
    return {
      isBusy: false,
      employee: {},
      attendance: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffDailyAttendance")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Opens Clock Record information
     *
     * @param data
     */
    handleExpand(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Staff has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.$store
        .dispatch("fetchStaffClockRecord", {
          id: data.item.id,
          date: dayjs().format(),
        })
        .then(() => {
          this.$bvModal.show("clock-record-modal");
        });
    },

    handlePageChange(page) {
      this.$store.commit("setStaffDailyAttendancePage", page);
      this.fetch();
    },

    /**
     * Show the manual per-day attendance modal.
     *
     * @param data
     */
    showManageModal(data) {
      console.log(data);

      this.employee = data.item;
      console.log("Employee", this.employee);

      // Create temp. attendance
      if (data.item.attendance === null) {
        data.item.attendance = {
          id: null,
          date: dayjs().format(),
        };
      } else {
        data.item.attendance.date = dayjs().format();
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-manage-modal");
    },

    showUpdateModal(data) {
      console.log(data);
      this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Staff doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      if (data.item.attendance.in === null) {
        this.$bvModal.msgBoxOk("Staff doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-update-time-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffDailyAttendanceList",
      pagination: "getStaffDailyAttendancePagination",
      records: "getStaffClockRecordList",
      isBusyStore: "getStaffDailyAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
